import { Box, makeStyles, Typography } from "@material-ui/core";

import styles from "src/assets/jss/views/footerStyle";

const useStyles = makeStyles(styles);

export default function Footer() {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Typography className={classes.infoLink}>Copyright © {new Date().getFullYear()} Temple Fork Outfitters™ All Rights Reserved</Typography>
    </Box>
  );
}
