import { Box, Container, useMediaQuery, useTheme } from "@material-ui/core";
import { useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";

import authSelectors from "src/modules/auth/authSelectors";
import Header from "src/views/layout/Header";
import Menu from "src/views/layout/Menu";
import Footer from "src/views/layout/Footer";

export default function Layout(props) {
  const match = useRouteMatch();
  const theme = useTheme();
  const hideMenu = useMediaQuery(theme.breakpoints.down("sm"));
  
  const currentUser = useSelector(authSelectors.selectCurrentUser);

  return (
    <div className="main-content">
      <Header currentUser={currentUser} />
      <Container style={{ padding: 0, maxWidth: "100%" }}>
        <Box className="layout">
          {!hideMenu && (
            <Menu url={match.url} />
          )}
          <div className="content">
            {props.children}
            <Footer />
          </div>
        </Box>
      </Container>
    </div>
  );
}
