const privateRoutes = [
  {
    path: "/",
    loader: () => import("src/views/dashboard/DashboardPage"),
    permissionRequired: null,
    exact: true,
  },
  {
    path: "/order-history",
    loader: () => import("src/views/order/OrderPage"),
    permissionRequired: null,
    exact: true,
  },
  {
    path: "/incentives",
    loader: () => import("src/views/incentive/IncentivePage"),
    permissionRequired: null,
    exact: true,
  },
  {
    path: "/api-access",
    loader: () => import("src/views/api-access/APIAccessPage"),
    permissionRequired: null,
    exact: true,
  },
  {
    path: "/media",
    loader: () => import("src/views/media/MediaPage"),
    permissionRequired: null,
    exact: true,
  },
  {
    path: "/media/assets",
    loader: () => import("src/views/media/MediaAssetsPage"),
    permissionRequired: null,
    exact: true,
  },
  {
    path: "/stock-levels",
    loader: () => import("src/views/stock-level/StockLevelPage"),
    permissionRequired: null,
    exact: true,
  },
  {
    path: "/logout",
    loader: () => import("src/views/auth/SignoutPage"),
    permissionRequired: null,
    exact: true,
  },
].filter(Boolean);

const publicRoutes = [
  {
    path: "/auth/signin",
    loader: () => import("src/views/auth/SigninPage"),
  },
].filter(Boolean);

const simpleRoutes = [
  {
    path: "/403",
    loader: () => import("src/views/shared/errors/Error403Page"),
  },
  {
    path: "/500",
    loader: () => import("src/views/shared/errors/Error500Page"),
  },
  // {
  //   path: "**",
  //   loader: () => import("src/views/shared/errors/Error404Page"),
  // },
].filter(Boolean);

const routes = {
  privateRoutes,
  publicRoutes,
  simpleRoutes,
};

export default routes;
