import DashboardIcon from "@material-ui/icons/Dashboard";

import { ReactComponent as BagIcon } from "src/assets/img/shopping-bag.svg";
import { ReactComponent as MediaIcon } from "src/assets/img/perm-media.svg";
// import { ReactComponent as StarIcon } from "src/assets/img/glowing-star.svg";
// import { ReactComponent as GiftIcon } from "src/assets/img/gift.svg";
import { ReactComponent as APIIcon } from "src/assets/img/baseline-api.svg";
import { ReactComponent as StockIcon } from "src/assets/img/fluent_box.svg";

export default [
  {
    path: "/",
    exact: true,
    icon: DashboardIcon,
    label: "Dashboard",
    permssionRequired: null,
  },
  {
    path: "/order-history",
    label: "Order History",
    icon: BagIcon,
    permissionRequired: null,
  },
  {
    path: "/stock-levels",
    label: "Stock Levels",
    icon: StockIcon,
    permissionRequired: null,
  },
  {
    path: "/media",
    label: "Media",
    icon: MediaIcon,
    permissionRequired: null,
  },
  // {
  //   path: "/incentives",
  //   label: "Incentives",
  //   icon: StarIcon,
  //   permissionRequired: null,
  // },
  // {
  //   path: "/products",
  //   label: "New Products",
  //   icon: GiftIcon,
  //   permissionRequired: null,
  // },
  {
    path: "/api-access",
    label: "API Access",
    icon: APIIcon,
    permissionRequired: null,
  },

].filter(Boolean);
