import { h7Font } from "src/assets/jss/variables";

const headerStyle = (theme) => ({
  navbar: {
    minHeight: "80px",
    backgroundColor: "#000",
    justifyContent: "center",
  },
  navbox: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "right",
    }
  },
  toolbar: {
    padding: "0 0 0 48px",
    justifyContent: "space-between",
    height: "80px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 0 0 18px",
    }
  },
  logoWrap: {
    alignItems: "flex-start",
    width: "200px",
    display: "flex",
    flexDirection: "column" as const,
  },
  logo: {
    height: "50px",
    [theme.breakpoints.down("sm")]: {
      height: "40px",
    }
  },
  navlinks: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    height: "80px",
    justifyContent: "space-between",
  },
  link: {
    ...h7Font,
    color: "#fff",
    marginLeft: theme.spacing(3),
    textDecoration: "none",
  },
  iconlink: {
    color: "#fff",
    marginLeft: theme.spacing(2),
  },
  brand: {
    width: "108px",
    background: "#222222",
    fontSize: "21px",
    display: "flex",
    alignItems: "center",
    height: "100%",
    "text-align": "center",
    "font-weight": 700,
    "line-height": "21px",
  },
  logout: {
    width: "122px",
    height: "80px",
    background: "#222222",
    "font-weight": "bold",
    "font-size": "16px",
    "line-height": "21px",
    "text-transform": "uppercase",
    display: "flex",
    alignItems: "center",
    "text-align": "center",
    "justify-content": "center",
    cursor: "pointer",
  },
  accessText: {
    color: "#ffffff",
    fontSize: "0.6rem",
  }
});

export default headerStyle;
