import {
  AppBar,
  CssBaseline,
  makeStyles,
  Toolbar,
  useMediaQuery,
  useTheme,
  Box,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import DrawerComponent from "src/views/layout/DrawerComponent";
import logo from "src/assets/img/logo_white.png";
import { ReactComponent as LogOutSvg } from "src/assets/img/logout.svg";
import { ReactComponent as UserSvg } from "src/assets/img/user.svg";
import styles from "src/assets/jss/views/headerStyle";
import { useHistory } from "react-router-dom";
import moment from "moment";
const CryptoJS = require("crypto-js");
const useStyles = makeStyles(styles);

export default function Header({ currentUser }) {
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const handleLogout = () => {
    history.push("/logout");
  };

  // Encrypt a string containing the current timestamp with the user's password
  // Send that along with the user's 
  const handleSignWebsite = () => {
    if (currentUser) {
      const encryptData = CryptoJS.AES.encrypt(
        'validate|' + moment().unix(),
        currentUser.password
      ).toString();
      window.open(
        `https://tforods.com?tfo-login=${currentUser.customer_id}&tfo-login-data=${encryptData}`,
        "_blank"
      );
    }
  };
  return (
    <AppBar position="static" className={classes.navbar}>
      <CssBaseline />
      <Toolbar className={classes.toolbar}>
        <Box>
          <Link to="/" className={classes.logoWrap} onClick={handleSignWebsite}>
            <img src={logo} className={classes.logo} alt="TFO" />
            <Typography className={classes.accessText}>Access Consumer Experience</Typography>
          </Link>
        </Box>
        <Box sx={{ flex: 1 }} className={classes.navbox}>
          {isMobile ? (
            <DrawerComponent />
          ) : (
            <div className={classes.navlinks}>
              <div className={classes.brand}>DEALER PORTAL</div>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <div className={"user-info"}>
                  <UserSvg />
                  <Box className="user-name">{currentUser.email}</Box>
                  <KeyboardArrowDownIcon />
                </div>
                <div className={classes.logout} onClick={handleLogout}>
                  <LogOutSvg style={{ marginRight: "10px" }} />
                  Logout
                </div>
              </Box>
            </div>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
}
