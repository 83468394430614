import {
  primaryColor,
  whiteColor,
  h6Font,
  body2Font,
} from "src/assets/jss/variables";

const footerStyle = (theme) => ({
  root: {
    // backgroundColor: primaryColor[0],
    borderTop: "1px solid #DCDCDC",
    height: "75px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: '0 50px',
    [theme.breakpoints.down("sm")]: {
      padding: '0 20px',
    }
  },
  navLinks: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  link: {
    ...h6Font,
    color: whiteColor,
    textDecoration: "none",
    marginRight: "40px",
  },
  mailSection: {
    color: whiteColor,
  },
  mailLabel: {
    ...h6Font,
    color: whiteColor,
  },
  mailText: {
    color: whiteColor,
  },
  mailInput: {
    color: whiteColor,
    outline: "none",
    border: `${primaryColor[1]} solid 1px`,
    lineHeight: "20px",
    padding: "3px 24px 3px 8px",
    marginTop: "5px",
    backgroundSize: "20px 14px",
    backgroundColor: primaryColor[0],
  },
  logo: {
    height: "60px",
  },
  infoLinks: {
    display: "flex",
  },
  infoLink: {
    ...body2Font,
    color: "#141414",
  },
  socialLink: {
    color: "#aaa",
    marginLeft: "15px",
    "& svg": {
      fontSize: "32px",
    },
  },
  test1: {
    border: "1px solid grey",
  },
  test2: {
    paddingTop: "5px",
    margin: 0,
    // paddingBottom: "5px",
  },
});

export default footerStyle;
